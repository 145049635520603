(function (angular, _, Prism, undefined) {
    "use strict";
    var core = angular.module("mikesCore");

    core.directive("prism", [function () {
        return {
            restrict: "A",
            link: function ($scope, element, attrs) {
                $scope.$watch(function () {
                    return element[0].getElementsByTagName("pre").length;
                }, function (elements) {
                    Prism.highlightAll();
                    _.each(element[0].getElementsByTagName("pre"), function (el) {
                        if (el.className.indexOf("whiteframe") === -1) {
                            el.className += " md-whiteframe-z2";
                        }
                    });
                });
            }
        };
    }]);

})(angular, _, Prism);
